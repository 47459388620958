/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import arrow1 from '../../assets/arrow1.svg';
import arrow2 from '../../assets/arrow2.svg';
import arrow3 from '../../assets/arrow3.svg';

const Arrows = () => {
  return (
    <>
      <img
        className="absolute left-[188px] top-[274px] h-[12px] w-[36px]"
        alt="Arrow"
        src={arrow1}
      />
      <img
        className="absolute left-[387px] top-[274px] h-[12px] w-[36px]"
        alt="Arrow"
        src={arrow2}
      />
      <img
        className="absolute left-[388px] top-[488px] h-[20px] w-[234px]"
        alt="Arrow"
        src={arrow3}
      />
    </>
  );
};

export default Arrows;
