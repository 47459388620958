/**
 * Copyright 2023 Google LLC
 */

import React, { createContext, useContext, useEffect, useState } from 'react';

import { getIdToken, onAuthStateChanged, signInAnonymously, User } from 'firebase/auth';

import { auth } from '../firebase/config';

interface AppContextInterface {
  currentUser: User | null;
  loggedIn: boolean;
  token: string;
}

type AuthProviderProps = {
  children: React.ReactNode;
};

const UserContext = createContext<AppContextInterface>({} as AppContextInterface);

export const AuthContextProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState('');

  signInAnonymously(auth).catch((error) => {
    console.log(error);
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        getIdToken(user).then((token) => {
          setToken(token);
        });
      }
      setIsLoggedIn(true);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ currentUser, loggedIn, token }}>{children}</UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
