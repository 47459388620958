/**
 * Copyright 2023 Google LLC
 */

import React, { useEffect, useState } from 'react';
import getCollection from '../../firebase/getCollection';
import Runtimes from './Runtimes';
import Loadbalancing from './Loadbalancing';
import Persist from './Persist';
import Arrows from './Arrows';
import Vertex from './Vertex';
import Messaging from './Messaging';
import NotReady from './NotReady';
import googleCloudLogo from '../../assets/google_cloud_logo.svg';

const Deployment = () => {
  type DeploymentState = {
    cloudloadbalancer: boolean;
    crcommandproxy: boolean;
    crcloudfrontend: boolean;
    crdeploymentstate: boolean;
    crimagecapture: boolean;
    crtelemetryproxy: boolean;
    gcs_capture_images: boolean;
    firestoreloadgcs: boolean;
    google_artifact_registry_repository: boolean;
    pubsubscriptions_cmd: boolean;
    pubsubscriptions_telem: boolean;
    vertex: boolean;
  };

  const [isButtonPressed, setIsButtonPressed] = useState<any>([]);
  const [deploymentCollection, setDeploymentCollection] = useState<any>([]);
  const [deploymentProgress, setDeploymentProgress] = useState<DeploymentState>({
    cloudloadbalancer: true,
    crcommandproxy: false,
    crcloudfrontend: false,
    crdeploymentstate: false,
    crimagecapture: false,
    crtelemetryproxy: false,
    gcs_capture_images: false,
    firestoreloadgcs: false,
    google_artifact_registry_repository: false,
    pubsubscriptions_cmd: false,
    pubsubscriptions_telem: false,
    vertex: true,
  });

  useEffect(() => {
    getCollection(setDeploymentCollection, 'deployment_state');
    getCollection(setIsButtonPressed, 'buttonpressed');
  }, []);

  useEffect(() => {
    deploymentCollection.map((component: any) => {
      if (component.component in deploymentProgress) {
        setDeploymentProgress((prevState: any) => ({ ...prevState, [component.component]: true }));
      }
    });
  }, [deploymentCollection]);

  if (
    (isButtonPressed && isButtonPressed.length > 0) ||
    (deploymentCollection && deploymentCollection.length > 0)
  ) {
    return (
      <div className="flex flex-row justify-center bg-[#DEE1E6]">
        <div className="flex h-screen w-[809px] flex-col justify-center bg-[#DEE1E6]">
          <div className="relative mb-2 h-[49px] w-[809px] bg-[#519bf7] shadow-[0px_4px_4px_#00000040]">
            <div className="relative left-[9px] top-[12px] w-[400px] text-center text-[20px] font-normal leading-[normal] tracking-[0] text-white [font-family:'Roboto-Regular',Helvetica]">
              {`Project ID: ${process.env.REACT_APP_PROJECT_ID}`}
            </div>
          </div>
          <div className="relative h-[556px] w-[809px] bg-[#f6f6f6] shadow-[0px_4px_4px_#00000040]">
            <img
              className="relative left-[628px] top-[20px] h-[24px] w-[156px]"
              alt="Google Cloud Logo"
              src={googleCloudLogo}
            />
            <Runtimes deploymentProgress={deploymentProgress} />
            <Loadbalancing deploymentProgress={deploymentProgress} />
            <Persist deploymentProgress={deploymentProgress} />
            <Vertex deploymentProgress={deploymentProgress} />
            <Messaging deploymentProgress={deploymentProgress} />
            <Arrows />
          </div>
        </div>
      </div>
    );
  } else {
    return <NotReady />;
  }
};

export default Deployment;
