/**
 * Copyright 2023 Google LLC
 */

import React, { FC } from 'react';
import vertex from '../../assets/vertexai.svg';

type DeploymentState = {
  deploymentProgress: {
    cloudloadbalancer: boolean;
    crcommandproxy: boolean;
    crcloudfrontend: boolean;
    crdeploymentstate: boolean;
    crimagecapture: boolean;
    crtelemetryproxy: boolean;
    gcs_capture_images: boolean;
    firestoreloadgcs: boolean;
    google_artifact_registry_repository: boolean;
    pubsubscriptions_cmd: boolean;
    pubsubscriptions_telem: boolean;
    vertex: boolean;
  };
};

const Vertex: FC<DeploymentState> = (props: DeploymentState) => {
  return (
    <div className="absolute left-[628px] top-[60px] h-[166px] w-[156px] bg-[#e6f1fc]">
      <div className="absolute left-[3px] top-[14px] w-[150px] text-center text-[16px] font-medium leading-[normal] tracking-[0] text-[#5f5f5f] [font-family:'Roboto-Medium',Helvetica]">
        AI
      </div>
      {props.deploymentProgress.vertex ? (
        <>
          <img
            className="absolute left-[38px] top-[50px] h-[80px] w-[80px]"
            alt="Vertexai"
            src={vertex}
          />
          <div className="absolute left-[25px] top-[130px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] [font-family:'Roboto-Bold',Helvetica]">
            Vertex
          </div>
        </>
      ) : (
        <>
          <img
            className="absolute left-[38px] top-[50px] h-[80px] w-[80px] opacity-25"
            alt="Vertexai"
            src={vertex}
          />
          <div className="absolute left-[25px] top-[130px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] opacity-25 [font-family:'Roboto-Bold',Helvetica]">
            Vertex
          </div>
        </>
      )}
    </div>
  );
};

export default Vertex;
