/**
 * Copyright 2023 Google LLC
 */

import QRCode from 'react-qr-code';
import React from 'react';

const TeamQRCode = ({ width = '70px' }) => {
  return (
    <div className="p-5">
      <QRCode
        style={{ height: 'auto', width: width }}
        value={
          `${process.env.REACT_APP_TEAM_UI_URL}?email=${process.env.REACT_APP_PROJECT_ID}@dronederby.io&password=${process.env.REACT_APP_PROJECT_ID}` ||
          'https://please-set-the-frontend-url-var'
        }
        viewBox={`0 0 256 256`}
      />
    </div>
  );
};

export default TeamQRCode;
