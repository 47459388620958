/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import gcplogo from '../../assets/gcplogo.svg';
import TeamQRCode from './TeamQRCode';

const NotReady = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          color: 'white',
          fontSize: '6vh',
          backgroundColor: '#1E1E20',
        }}
      >
        <div style={{ marginBottom: '60px', background: 'white' }}>
          <TeamQRCode width={'9vw'} />
        </div>
        <img
          alt="google cloud logo"
          src={gcplogo}
          style={{ marginBottom: '60px', width: '50vw' }}
        />
        <div>Waiting for Infrastructure Deployment</div>
      </div>
    </>
  );
};

export default NotReady;
