/**
 * Copyright 2023 Google LLC
 */

import React from 'react';
import Deployment from './deploymentProgress/Deployment';
import { UserAuth } from '../contexts/AuthContext';

const DeploymentProgress = () => {
  const { currentUser } = UserAuth();

  return <>{currentUser && <Deployment />}</>;
};

export default DeploymentProgress;
