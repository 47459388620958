/**
 * Copyright 2023 Google LLC
 */

import React, { FC } from 'react';
import artifactRegistry from '../../assets/artifact_registry.svg';
import cloudStorage from '../../assets/cloud_storage.svg';
import firestore from '../../assets/firestore.svg';

type DeploymentState = {
  deploymentProgress: {
    cloudloadbalancer: boolean;
    crcommandproxy: boolean;
    crcloudfrontend: boolean;
    crdeploymentstate: boolean;
    crimagecapture: boolean;
    crtelemetryproxy: boolean;
    gcs_capture_images: boolean;
    firestoreloadgcs: boolean;
    google_artifact_registry_repository: boolean;
    pubsubscriptions_cmd: boolean;
    pubsubscriptions_telem: boolean;
    vertex: boolean;
  };
};

const Persist: FC<DeploymentState> = (props: DeploymentState) => {
  return (
    <div className="absolute left-[427px] top-[18px] h-[432px] w-[156px] bg-[#e6f1fc]">
      <div className="absolute left-[3px] top-[10px] w-[150px] text-center text-[16px] font-medium leading-[normal] tracking-[0] text-[#5f5f5f] [font-family:'Roboto-Medium',Helvetica]">
        Storage / DB
      </div>
      <div className="absolute left-[31px] top-[273px] h-[117px] w-[89px]">
        {props.deploymentProgress.gcs_capture_images ? (
          <>
            <img
              className="absolute left-0 top-[10px] h-[90px] w-[89px]"
              alt="Cloud storage"
              src={cloudStorage}
            />
            <div className="absolute left-[8px] top-[95px] w-[78px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] [font-family:'Roboto-Bold',Helvetica]">
              Cloud Storage
            </div>
          </>
        ) : (
          <>
            <img
              className="absolute left-0 top-[10px] h-[90px] w-[89px] opacity-25"
              alt="Cloud storage"
              src={cloudStorage}
            />
            <div className="absolute left-[8px] top-[95px] w-[78px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] opacity-25 [font-family:'Roboto-Bold',Helvetica]">
              Cloud Storage
            </div>
          </>
        )}
      </div>
      {props.deploymentProgress.google_artifact_registry_repository ? (
        <>
          <img
            className="absolute left-[43px] top-[43px] h-[69px] w-[69px]"
            alt="Artifact registry"
            src={artifactRegistry}
          />
          <div className="absolute left-[39px] top-[118px] w-[78px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] [font-family:'Roboto-Bold',Helvetica]">
            Artifact Registry
          </div>
        </>
      ) : (
        <>
          <img
            className="absolute left-[43px] top-[43px] h-[69px] w-[69px] opacity-25"
            alt="Artifact registry"
            src={artifactRegistry}
          />
          <div className="absolute left-[39px] top-[118px] w-[78px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] opacity-25 [font-family:'Roboto-Bold',Helvetica]">
            Artifact Registry
          </div>
        </>
      )}
      {props.deploymentProgress.firestoreloadgcs ? (
        <>
          <img
            className="absolute left-[43px] top-[172px] h-[69px] w-[69px]"
            alt="Firestore"
            src={firestore}
          />
          <div className="absolute left-[39px] top-[247px] w-[78px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] [font-family:'Roboto-Bold',Helvetica]">
            Firestore
          </div>
        </>
      ) : (
        <>
          <img
            className="absolute left-[43px] top-[172px] h-[69px] w-[69px] opacity-25"
            alt="Firestore"
            src={firestore}
          />
          <div className="absolute left-[39px] top-[247px] w-[78px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] opacity-25 [font-family:'Roboto-Bold',Helvetica]">
            Firestore
          </div>
        </>
      )}
    </div>
  );
};

export default Persist;
