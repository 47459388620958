/**
 * Copyright 2023 Google LLC
 */

import React, { FC } from 'react';
import cloudLoadbalancing from '../../assets/cloud_load_balancing.svg';

type DeploymentState = {
  deploymentProgress: {
    cloudloadbalancer: boolean;
    crcommandproxy: boolean;
    crcloudfrontend: boolean;
    crdeploymentstate: boolean;
    crimagecapture: boolean;
    crtelemetryproxy: boolean;
    gcs_capture_images: boolean;
    firestoreloadgcs: boolean;
    google_artifact_registry_repository: boolean;
    pubsubscriptions_cmd: boolean;
    pubsubscriptions_telem: boolean;
    vertex: boolean;
  };
};

const Loadbalancing: FC<DeploymentState> = (props: DeploymentState) => {
  return (
    <div className="absolute left-[25px] top-[18px] h-[516px] w-[156px] bg-[#e6f1fc]">
      <div className="absolute left-[3px] top-[14px] w-[150px] text-center text-[16px] font-medium leading-[normal] tracking-[0] text-[#5f5f5f] [font-family:'Roboto-Medium',Helvetica]">
        Loadbalancing
      </div>
      {props.deploymentProgress.cloudloadbalancer ? (
        <>
          <img
            className="absolute left-[48px] top-[208px] h-[59px] w-[59px]"
            alt="Cloud load balancing"
            src={cloudLoadbalancing}
          />
          <div className="absolute left-[39px] top-[267px] w-[78px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] [font-family:'Roboto-Bold',Helvetica]">
            Cloud Load Balancing
          </div>
        </>
      ) : (
        <>
          <img
            className="absolute left-[48px] top-[208px] h-[59px] w-[59px] opacity-25"
            alt="Cloud load balancing"
            src={cloudLoadbalancing}
          />
          <div className="absolute left-[39px] top-[267px] w-[78px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] opacity-25 [font-family:'Roboto-Bold',Helvetica]">
            Cloud Load Balancing
          </div>
        </>
      )}
    </div>
  );
};

export default Loadbalancing;
