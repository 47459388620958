/**
 * Copyright 2023 Google LLC
 */

import React, { FC } from 'react';
import pubsub from '../../assets/pubsub.svg';

type DeploymentState = {
  deploymentProgress: {
    cloudloadbalancer: boolean;
    crcommandproxy: boolean;
    crcloudfrontend: boolean;
    crdeploymentstate: boolean;
    crimagecapture: boolean;
    crtelemetryproxy: boolean;
    gcs_capture_images: boolean;
    firestoreloadgcs: boolean;
    google_artifact_registry_repository: boolean;
    pubsubscriptions_cmd: boolean;
    pubsubscriptions_telem: boolean;
    vertex: boolean;
  };
};

const Messaging: FC<DeploymentState> = (props: DeploymentState) => {
  return (
    <div className="absolute left-[628px] top-[238px] h-[295px] w-[156px] bg-[#e6f1fc]">
      <div className="absolute left-[3px] top-[12px] w-[150px] text-center text-[16px] font-medium leading-[normal] tracking-[0] text-[#5f5f5f] [font-family:'Roboto-Medium',Helvetica]">
        Pub / Sub
      </div>
      {props.deploymentProgress.pubsubscriptions_cmd ? (
        <>
          <img
            className="absolute left-[45px] top-[53px] h-[65px] w-[65px]"
            alt="Pubsub"
            src={pubsub}
          />

          <div className="absolute left-[25px] top-[118px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] [font-family:'Roboto-Bold',Helvetica]">
            Command Topics
          </div>
        </>
      ) : (
        <>
          <img
            className="absolute left-[45px] top-[53px] h-[65px] w-[65px] opacity-25"
            alt="Pubsub"
            src={pubsub}
          />
          <div className="absolute left-[25px] top-[118px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] opacity-25 opacity-25 [font-family:'Roboto-Bold',Helvetica]">
            Command Topics
          </div>
        </>
      )}
      {props.deploymentProgress.pubsubscriptions_telem ? (
        <>
          <img
            className="absolute left-[45px] top-[165px] h-[65px] w-[65px]"
            alt="Pubsub"
            src={pubsub}
          />
          <div className="absolute left-[25px] top-[230px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] [font-family:'Roboto-Bold',Helvetica]">
            Telemetry Topics
          </div>
        </>
      ) : (
        <>
          <img
            className="absolute left-[45px] top-[165px] h-[65px] w-[65px] opacity-25"
            alt="Pubsub"
            src={pubsub}
          />
          <div className="absolute left-[25px] top-[230px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] opacity-25 [font-family:'Roboto-Bold',Helvetica]">
            Telemetry Topics
          </div>
        </>
      )}
    </div>
  );
};

export default Messaging;
