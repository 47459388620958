/**
 * Copyright 2023 Google LLC
 */

import React, { FC } from 'react';
import cloudRun from '../../assets/cloud_run.svg';
import TeamQRCode from './TeamQRCode';

type DeploymentState = {
  deploymentProgress: {
    cloudloadbalancer: boolean;
    crcommandproxy: boolean;
    crcloudfrontend: boolean;
    crdeploymentstate: boolean;
    crimagecapture: boolean;
    crtelemetryproxy: boolean;
    gcs_capture_images: boolean;
    firestoreloadgcs: boolean;
    google_artifact_registry_repository: boolean;
    pubsubscriptions_cmd: boolean;
    pubsubscriptions_telem: boolean;
    vertex: boolean;
  };
};

const Runtimes: FC<DeploymentState> = (props: DeploymentState) => {
  return (
    <>
      <div className="absolute left-[226px] top-[18px] h-[516px] w-[156px] bg-[#e6f1fc]">
        <div className="absolute left-[3px] top-[14px] w-[150px] text-center text-[16px] font-medium leading-[normal] tracking-[0] text-[#5f5f5f] [font-family:'Roboto-Medium',Helvetica]">
          Runtimes
        </div>
        <div className="absolute left-[25px] top-[20px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] [font-family:'Roboto-Bold',Helvetica]">
          <TeamQRCode />
        </div>
        {props.deploymentProgress.crcloudfrontend ? (
          <>
            <img
              className="absolute left-[42px] top-[128px] h-[72px] w-[72px]"
              alt="Cloud run"
              src={cloudRun}
            />
            <div className="absolute left-[25px] top-[200px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] [font-family:'Roboto-Bold',Helvetica]">
              Team Frontend
            </div>
          </>
        ) : (
          <>
            <img
              className="absolute left-[42px] top-[128px] h-[72px] w-[72px] opacity-25"
              alt="Cloud run"
              src={cloudRun}
            />
            <div className="absolute left-[25px] top-[200px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] opacity-25 [font-family:'Roboto-Bold',Helvetica]">
              Team Frontend
            </div>
          </>
        )}

        {props.deploymentProgress.crcommandproxy ? (
          <>
            <img
              className="absolute left-[42px] top-[221px] h-[72px] w-[72px]"
              alt="Cloud run"
              src={cloudRun}
            />
            <div className="absolute left-[25px] top-[293px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] [font-family:'Roboto-Bold',Helvetica]">
              Command Proxy
            </div>
          </>
        ) : (
          <>
            <img
              className="absolute left-[42px] top-[221px] h-[72px] w-[72px] opacity-25"
              alt="Cloud run"
              src={cloudRun}
            />
            <div className="absolute left-[25px] top-[293px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] opacity-25 [font-family:'Roboto-Bold',Helvetica]">
              Command Proxy
            </div>
          </>
        )}

        {props.deploymentProgress.crtelemetryproxy ? (
          <>
            <img
              className="absolute left-[42px] top-[314px] h-[72px] w-[72px]"
              alt="Cloud run"
              src={cloudRun}
            />
            <div className="absolute left-[25px] top-[386px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] [font-family:'Roboto-Bold',Helvetica]">
              Telemetry Proxy
            </div>
          </>
        ) : (
          <>
            <img
              className="absolute left-[42px] top-[314px] h-[72px] w-[72px] opacity-25"
              alt="Cloud run"
              src={cloudRun}
            />
            <div className="absolute left-[25px] top-[386px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] opacity-25 [font-family:'Roboto-Bold',Helvetica]">
              Telemetry Proxy
            </div>
          </>
        )}

        {props.deploymentProgress.crimagecapture ? (
          <>
            <img
              className="absolute left-[42px] top-[407px] h-[72px] w-[72px]"
              alt="Cloud run"
              src={cloudRun}
            />
            <div className="absolute left-[25px] top-[479px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] [font-family:'Roboto-Bold',Helvetica]">
              Image Capture
            </div>
          </>
        ) : (
          <>
            <img
              className="absolute left-[42px] top-[407px] h-[72px] w-[72px] opacity-25"
              alt="Cloud run"
              src={cloudRun}
            />
            <div className="absolute left-[25px] top-[479px] w-[106px] text-center text-[12px] font-bold leading-[normal] tracking-[0] text-[#808080] opacity-25 [font-family:'Roboto-Bold',Helvetica]">
              Image Capture
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Runtimes;
